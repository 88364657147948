#preloader
	position: fixed
	z-index: 2147483647
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-color: $colorWhite

#status
	position: absolute
	top: 50%
	left: 50%
	margin: -100px 0 0 -100px
	width: 250px
	height: auto
	background: url("/img/preloader.gif") no-repeat center top

#status h4
	padding-top: 50px
	font-size: 18px
	text-align: center
	text-transform: uppercase

#status span
	display: block
	margin-top: 2px
