input[type=text],
input[type=submit],
textarea
	margin-bottom: 1.5em
	box-sizing: border-box
	width: 100%
	padding: 0.5em
	font-size: 1em

input,
button,
select,
textarea
	font-family: "Open Sans", Arial, sans-serif
