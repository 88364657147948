.location
	position: relative
	margin-bottom: 26px

	.image
		position: relative
		margin: 0 0 1px
		width: 100%
		height: 300px
		background-repeat: no-repeat
		background-position: center center
		background-size: cover

		h2
			position: absolute
			bottom: 0
			margin: 0
			width: 100%
			padding: 15px 30px
			background-color: $colorPurple
			text-align: center
			text-transform: uppercase
			color: $colorPink

	.info
		ul
			list-style: none outside none
			margin: 0
			padding: 0
			// text-align: left

			li
				margin-bottom: 1px
				padding: 15px 30px
				background-color: $colorPurple
				line-height: 20px
				text-align: center
				color: $colorWhite

				a
					color: $colorWhite

				.fa
					display: inline-block
					margin-right: 10px

				.free
					position: relative
					top: -1px
					left: 4px
					border-radius: 3px
					width: 55px
					padding: 4px 8px
					background: #d9b346
					font-size: 11px
					line-height: 0
					font-weight: 500
					text-align: center
					text-transform: uppercase
					letter-spacing: 1px
					color: #fff

	.btn-map
		display: block
		transition: background 0.2s ease-in-out
		border-bottom: 5px solid transparent
		padding: 15px 30px
		background-color: $colorPink
		line-height: 20px
		text-align: center
		text-transform: uppercase
		color: $colorWhite

		&:hover,
		&:focus
			transition: background 0.2s ease-in-out
			background-color: $colorMintGreen
			cursor: pointer
			text-decoration: none
			color: $colorPurple

	.badge
		position: absolute
		z-index: 1
		top: 0
		left: 0
		border-radius: 0
		width: 100%
		padding: 10px 0 8px
		background-color: $colorPink
		font-weight: normal
		text-transform: uppercase
		color: $colorWhite


// .price-plan
// 	position: relative
// 	width: auto
// 	background: repeat scroll 0 0 rgba(0,0,0,0.7)
//
// 	&:after
// 		content: " "
// 		display: block
// 		position: absolute
// 		top: 0
// 		left: 0
// 		right: 0
// 		bottom: 0
// 		background-color: #000
// 		z-index: 1
// 		opacity: 0
// 		transition: opacity 0.2s ease-out 0s
//
// 	&:hover:after
// 		opacity: 1
//
// 	 > *
// 		position: relative
// 		z-index: 10
