#team
	.info
		margin: 0 auto
		max-width: 800px
		padding: 40px 0 20px
		line-height: 1.4

.team-member
	position: relative
	display: block
	text-align: center
	text-decoration: none

	img
		transition: all 0.5s ease-in-out 0s
		border: 15px solid rgba(0,0,0,0.03)
		border-radius: 50%
		width: 100%
		max-width: 280px
		height: auto

	//.name
	h3
		display: inline-block
		transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s
		margin-bottom: 0
		border-right: 1px solid rgba(204,204,204,0)
		border-left: 1px solid rgba(204,204,204,0)
		width: auto
		padding: 0 30px

	&:hover
		img
			transition: border 0.4s ease 0s
			border-color: rgba(0,0,0,0.1)

		//@TODO
		h3
			transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s
			border-right: 1px solid #ccc
			border-left: 1px solid #ccc
			padding: 0 10px
			background: #f2f2f2
