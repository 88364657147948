.map-overlay
	position: absolute
	z-index: 99
	top: 0
	left: 0
	visibility: visible
	opacity: 1
	transition: all 0.3s linear 0s
	width: 100%
	height: 80%
	background: none repeat scroll 0 0 rgba(131,113,161,0.9)

#map
	position: absolute
	z-index: 99
	width: 100%
	height: 94%


.popup-btn
	display: block
	transition: all 0.2s ease-in-out 0s
	border: none
	box-sizing: border-box
	width: 100%
	padding: 30px
	background: $colorPurple
	text-align: center
	text-transform: uppercase
	text-decoration: none
	color: $colorWhite

	&:hover,
	&:focus
		transition: background 0.2s ease-in-out
		background-color: $colorMintGreen
		cursor: pointer
		text-decoration: none
		color: $colorPurple

.popup-btn i
	margin-right: 10px
