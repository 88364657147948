// DEFAULT COLOR
$colorWhite: #ffffff
$colorBlack: #000000

// CORPORATE COLORS
// $colorPink: #d67fb4
$colorPink: #eb68b6
$colorPinkDark: #a04a7e

$colorPurple: #592484
$colorPurpleDark: #461b5d

$colorMintGreenLight: #a6ca5e
$colorMintGreen: #9de64e
$colorMintGreenDarker: #9de64e
$colorMintGreenDarkest: #9de64e
// $colorMintGreen: #8cc150
// $colorMintGreenDarker: #66b445
// $colorMintGreenDarkest: #4caf41

$colorGrey: #DADADA
$colorLightGrey: #E6E6E6

// SOCIAL MEDIA CORPORATE COLORS
$colorFacebook: #3b5998
$colorInstagram: #ba7b3d
$colorTwitter: #5ad2eb
$colorDribbble: #f57b71
$colorPinterest: #d84848
$colorYelp: #c41200

// LINKS

// BACKGROUNDS
.bg-purple
	background-color: $colorPurple
	color: $colorWhite

.bg-pink
	background-color: $colorPink
	color: $colorWhite

.bg-white
	background-color: $colorWhite
	color: $colorPurple


// FONTS
.text-purple
	color: $colorPurple

.text-pink
	color: $colorPink

.text-white
	color: $colorWhite

// in reveal modal
//$f2f2f2
//aaa

//
// .light-overlay,
// .lighter-overlay,
// .darker-overlay {
//   display: block;
//   position: relative;
//   width: 100%;
//   height: 100%;
// }
// .darker-overlay {
//   background-color: rgba(0,0,0,0.7);
// }
// .light-overlay {
//   background-color: #f2f2f2;
// }
// .lighter-overlay {
//   background-color: #f7f7f7;
// }
