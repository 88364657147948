/*


div,
article,
section,
header,
footer,
nav {
  position: relative;
}
 */
.group:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.blankdivider10 {
  display: block;
  height: 10px;
}
.blankdivider20 {
  display: block;
  height: 20px;
}
.blankdivider30 {
  display: block;
  height: 30px;
}
.headingborder {
  display: inline-block;
  padding: 25px;
}
.container-video {
  position: relative;
  height: 100%;
  min-height: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 750;
}
.placeholder {
  color: #fff;
}

#main-flexslider.flexslider {
  margin: 0;
  background: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#main-flexslider ul.slides li p,
#main-portfoliopage p,
#main-blogpage p {
  position: relative;
  margin-bottom: 0;
  color: #fff;
  font-size: 72px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}
.texteffect {
  position: relative !important;
  margin-left: 0 !important;
  padding: 5px 10px !important;
  font-family: "Oswald",sans-serif;
  font-size: 28px !important;
  font-weight: 300 !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: uppercase !important;
  background: none repeat scroll 0 0 rgba(255,255,255,0.1);
}
.texteffect strong {
  font-weight: 300 !important;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/05_overlayPattern.png");
  background-attachment: scroll;
  background-repeat: repeat;
}


i.icon-bglight {
  color: #444;
  background: #f7f7f7;
}
i.icon-bglight.active {
  color: #fff;
}
i.icon-bglight.active:hover {
  color: #444;
  background: #f7f7f7;
}
i.icon-bgdark {
  color: #f7f7f7;
  background: #333;
}
i.icon-bgdark:hover {
  color: #fff;
}
i.icon-bgdark.active:hover {
  color: #f7f7f7;
  background: #333;
}
i.icon {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
  text-align: center;
}
i.icon-2x {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}
i.icon-3x {
  width: 72px !important;
  height: 72px !important;
  line-height: 72px !important;
}
i.icon-4x {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px !important;
}
i.icon-5x {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px !important;
}
i.icon-rounded {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border-radius: 6px !important;
}
i.icon-rounded.icon-2x {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border-radius: 6px !important;
}
i.icon-rounded.icon-3x {
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  border-radius: 8px !important;
}
i.icon-rounded.icon-4x {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border-radius: 10px !important;
}
i.icon-rounded.icon-5x {
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  border-radius: 12px !important;
}
i.icon-circled {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
  background: #d9b346;
  color: #000;
}
i.icon-white {
  color: #fff;
}
.icon-6x {
  font-size: 6em;
}
.icon-6x.icon-border {
  border-width: 6px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
.icon-7x {
  font-size: 7em;
}
.icon-8x {
  font-size: 8em;
}
.icon-9x {
  font-size: 9em;
}
.icon-10x {
  font-size: 10em;
}
.big-icon {
  font-size: 10em;
}
section.spacer {
  position: relative;
  color: #fcfcfc;
  background-attachment: scroll;
  background-position: 50% 0;
  background-repeat: repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
section.spacer .container {
  padding: 30px 0 0;
}
section.spacer blockquote.large:before {
  color: #eee;
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
}
section.spacer blockquote cite {
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  section.spacer {
    background-attachment: scroll !important;
    background-position: center top !important;
  }
}

.iconface .bg-numero {
  position: absolute;
  top: 0;
  width: 140px;
  height: 140px;
  z-index: 9;
}
.iconface .numero {
  position: absolute;
  top: 10px;
  width: 120px;
  height: 120px;
  margin-left: 10px;
  font-size: 72px;
  line-height: 134px;
  text-align: center;
  z-index: 10;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px -1px #000;
  -moz-box-shadow: 0 0 5px -1px #000;
  box-shadow: 0 0 5px -1px #000;
}
.iconface .num {
  width: 140px;
  height: 140px;
  margin: 0 auto 20px;
}
.iconface .n2 {
  background: url("/img/06-icon-bg.png") no-repeat scroll 0 0 rgba(0,0,0,0);
}
.iconface .n20 {
  color: #d9b346;
  background: #fff;
}
.iconface .roda {
  -webkit-transform: rotate(-360deg);
  -moz-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  transform: rotate(-360deg);
  -webkit-transition: all 1.2s ease-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 1.2s ease-out 0s;
  -o-transition: all 1.2s ease-out 0s;
  transition: all 1.2s ease-out 0s;
}
.iconface:hover .bg-numero {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 1.2s ease-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 1.2s ease-out 0s;
  -o-transition: all 1.2s ease-out 0s;
  transition: all 1.2s ease-out 0s;
}


@media (min-width: 768px) and (max-width: 979px) {
  ul#menu-main>li>a {
    line-height: 32px;
  }
  #main-flexslider ul.slides li p,
  #main-portfoliopage p,
  #main-blogpage p {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  ul#menu-main>li>a {
    line-height: 32px;
  }
  #main-flexslider ul.slides li p,
  #main-portfoliopage p,
  #main-blogpage p {
    font-size: 42px;
  }
  #header-wrapper .span12 h1 {
    font-size: 38px;
    line-height: 42px;
  }
  .texteffect {
    font-size: 24px !important;
  }
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  #portfolio-wrap .portfolio-item {
    width: 50%;
  }
  .left-img {
    display: none;
  }
  .TabsPage .sp .tabs {
    height: auto;
  }
  .reveal-modal {
    left: 10%;
    right: 10%;
  }
}
@media (max-width: 480px) {
  #main-flexslider ul.slides li p,
  #main-portfoliopage p,
  #main-blogpage p {
    font-size: 26px;
  }
  #header-wrapper .span12 h1 {
    font-size: 28px;
    line-height: 32px;
  }
  .texteffect {
    font-size: 24px !important;
  }
  #portfolio-wrap .portfolio-item {
    width: 100%;
  }
  .left-img {
    display: none;
  }
  .TabsPage .sp .tabs {
    height: auto;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  section.section {
    padding: 1em 0;
  }
  #works .container {
    top: 0 !important;
  }
  .max-img {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  section#services h2 {
    margin-bottom: 0.25em;
  }
  section#services h2,
  section#services ul {
    text-align: center;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: auto;
    margin-right: auto;
  }
  #main-flexslider ul.slides li p,
  #main-portfoliopage p,
  #main-blogpage p {
    font-size: 28px;
  }
  #contact-form {
    overflow: hidden;
  }
  #contact-form input[type=submit] {
    width: 100%;
  }
}
.lt-ie9 .overlay {
  background: url("/img/ie/overlayWth90.png?1406838567");
}
.lt-ie9 .darker-overlay {
  background: url("/img/ie/overlayBlk70.png?1406834527");
}
.lt-ie9 .sidecontact i {
  background: url("/img/ie/circleWth80x80.png?1406834633");
}
.lt-ie9 #pricing .price-plan {
  background-color: transparent;
}
.lt-ie9 #pricing .price-plan:after {
  display: none;
}
.lt-ie9 #pricing .price-plan:hover:after {
  display: block;
}
.lt-ie9 #pricing .price-plan .price-plan-intrinsic .price-plan-link {
  background-image: url("/img/ie/pricing-pattern.png?1406835134");
}
.lt-ie9 #contact-form input[type=text],
.lt-ie9 #contact-form input[type=email],
.lt-ie9 #contact-form textarea {
  background: url("/img/ie/overlayWth10.png?1406835525");
}
.lt-ie9 #contact-form input[type=text]:focus,
.lt-ie9 #contact-form input[type=email]:focus,
.lt-ie9 #contact-form textarea:focus {
  background: url("/img/ie/overlayWth20.png?1406835648");
}
.lt-ie9 #contact-form input[type=text],
.lt-ie9 #contact-form input[type=email] {
  line-height: 50px;
}
