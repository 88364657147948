footer
	padding: 50px 0
	// background-color: rgba($colorWhite, 0.9)
	text-align: center
	color: #5e5e5e

	ul
		&.social-networks
			list-style: none
			padding: 0

			li
				display: inline-block

				a
					margin: 0 5px
					color: $colorWhite

					i
						transition: all 0.3s ease-in-out
						background-color: $colorPurple
						// background-color: #5e5e5e
						font-size: 20px
						color: $colorWhite

					&:hover,
					&:focus
						text-decoration: none

						i
							transition: all 0.3s ease-in-out

							&.icon-facebook
								background-color: $colorFacebook

							&.icon-instagram
								background-color: $colorInstagram

							&.icon-twitter
								background-color: $colorTwitter

							&.icon-dribbble
								background-color: $colorDribbble

							&.icon-pinterest
								background-color: $colorPinterest

							&.icon-yelp
								background-color: $colorYelp
