.contact-form
	position: relative
	z-index: 999

	input[type=text],
	input[type=email],
	textarea
		transition: background 0.2s ease-in-out
		margin-bottom: 30px
		box-shadow: none
		border: 0
		border-radius: 0
		padding: 0 16px
		background-color: rgba($colorWhite, 0.1)
		font-size: 16px
		text-align: left
		color: $colorWhite
		vertical-align: middle
		width: 100%

		&:focus
			transition: background 0.2s ease-in-out
			background-color: rgba($colorWhite, 0.5)
			color: $colorPurple
			outline: 0

		&.placeholder,
		&::placeholder
			color: rgba($colorWhite, 0.5)

	textarea
		height: 160px
		padding-top: 1em

	input[type=text],
	input[type=email]
		height: 50px

	input[type=submit]
		display: inline-block
		transition: background 0.3s ease-in-out
		margin: 0 0 20px
		border: 0
		border-radius: 0
		width: auto
		height: 50px
		padding: 0.5em 1em
		background-color: $colorPurple
		font-size: 16px
		color: $colorWhite

		&:hover,
		&:active
			transition: background 0.3s ease-in-out
			background-color: $colorMintGreen
			color: $colorPurple

	.validation
		display: none
		margin: -20px 0 20px

	.sendmessage
		display: none
		margin: 10px 0 30px
		border: 1px solid rgba($colorWhite, 0.3)
		padding: 15px 12px 5px
		text-align: center

		i
			margin-right: 10px
			font-size: 16px

// .cform-response-output
// 	max-width: 60%
// 	margin-left: 40% !important
// 	margin-top: 0.5em !important
// 	padding: 0.5em !important
// 	text-align: center
// 	-webkit-border-radius: 4px
// 	-moz-border-radius: 4px
// 	-ms-border-radius: 4px
// 	-o-border-radius: 4px
// 	border-radius: 4px
//
// .cform-not-valid-tip
// 	left: 0 !important
// 	width: 100% !important
// 	padding: 0.5em !important
// 	color: #888
// 	border: 1px dotted #ad3729 !important
// 	-webkit-border-radius: 4px
// 	-moz-border-radius: 4px
// 	-ms-border-radius: 4px
// 	-o-border-radius: 4px
// 	border-radius: 4px
// 	-webkit-box-sizing: border-box
// 	-moz-box-sizing: border-box
// 	box-sizing: border-box
