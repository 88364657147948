body
	overflow-x: hidden
	overflow-y: auto
	background: $colorWhite
	font-family: "Open Sans", Arial, sans-serif
	font-size: 14px
	line-height: 1.6
	font-weight: 400
	color: $colorPurpleDark

	> section
		overflow-x: hidden
		overflow-y: auto


// .hero-unit {
//   margin: 50px auto 0 auto;
//   width: 300px;
//   font-size: 18px;
//   font-weight: 200;
//   line-height: 30px;
//   background-color: #eee;
//   border-radius: 6px;
//   padding: 60px;
//   h1 {
//     font-size: 60px;
//     line-height: 1;
//     letter-spacing: -1px;
//   }
// }
