h1, h2, h3, h4, h5, h6
	font-family: "Open Sans", Arial, sans-serif
	line-height: 1.1em
	font-weight: 400

h1
	margin-bottom: 20px
	font-size: 36px

h2
	margin-bottom: 20px
	font-size: 30px

h3
	margin-bottom: 20px
	font-size: 20px

h4
	margin-bottom: 20px
	font-size: 22px

h5
	margin-bottom: 15px
	font-size: 18px

h6
	margin-bottom: 10px
	font-size: 16px

strong
	font-family: "Open Sans", Arial, sans-serif
	font-weight: 700

small
	font-size: 0.75em

em
	font-family: "Open Sans", Arial, sans-serif
	font-style: normal


p
	margin: 0 0 10px
	line-height: 1.6em

blockquote
	border: none
	padding: 0
	font-size: 18px

blockquote.large
	font-size: 32px
	line-height: 1.2em
	font-weight: 400

blockquote.large:before
	margin-right: 20px
	font-size: 64px
	font-family: "FontAwesome"
	content: "\f10d"

blockquote cite
	display: block
	font-size: 18px

blockquote cite a,
blockquote cite a:visited
	color: #555


.alignright
	text-align: right

.alignleft
	text-align: left

.aligncenter
	text-align: center

.text-left
	text-align: left !important

.uppercase
	text-transform: uppercase
