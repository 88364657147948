section
	.picture h3,
	.picture h4
		text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
		color: #fff

.section
	padding: 30px 0 50px

	&.gallery
		padding-bottom: 30px

.heading
	margin-bottom: 30px
	text-align: center

	h1
		margin-bottom: 0

	h4
		display: inline-table
		margin: 0
		font-size: 46px
		font-weight: 700
		text-align: center
		letter-spacing: -1px



// @media (min-width: 768px) and (max-width: 979px)
// .container
// padding: 0 20px
// 	.section
// 		overflow: hidden

// .section
// 	background-color: #fff
//
