// animate.css extendsions
.animated
	&.delay-01
		animation-delay: 0.1s
	&.delay-02
		animation-delay: 0.5s
	&.delay-03
		animation-delay: 1s
	&.delay-04
		animation-delay: 1.5s
	&.delay-05
		animation-delay: 2s
	&.delay-06
		animation-delay: 2.5s
	&.delay-07
		animation-delay: 3s
	&.delay-08
		animation-delay: 3.5s
	&.delay-09
		animation-delay: 4s
	&.delay-10
		animation-delay: 4.5s
	&.delay-11
		animation-delay: 5s
	&.delay-12
		animation-delay: 5.5s
	&.delay-13
		animation-delay: 6s
	&.delay-14
		animation-delay: 6.5s
	&.delay-15
		animation-delay: 7s
	&.delay-16
		animation-delay: 7.5s
	&.delay-17
		animation-delay: 8s
	&.delay-18
		animation-delay: 8.5s
	&.delay-19
		animation-delay: 9s
	&.delay-20
		animation-delay: 9.5s
//
// .pulse {
//   animation-duration: 1s;
//   animation-iteration-count: infinite;
// }
