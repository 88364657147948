#info
	padding-top: 20px

#about
	padding-bottom: 0

img
	&.find-us
		width: 100%
		max-width: 180px
		height: auto
