#header-wrapper
	position: relative
	width: 100%
	min-height: 600px
	padding-top: 160px
	text-align: center

#header-wrapper h1
	margin: 1em 0
	font-size: 4em
	font-weight: 400
	color: #fff

#header-wrapper h2
	margin: 0.5em 0
	font-family: "Open Sans", Arial, sans-serif
	font-size: 2em
	font-weight: normal
	text-shadow: 1px 1px 1px #333
	color: #fff

#header-wrapper .span12 h1
	margin-bottom: 10px
	padding: 0 2px
	background: none repeat scroll 0 0 rgba(0,0,0,0)
	font-family: "Oswald", sans-serif
	font-size: 52px
	line-height: 62px
	font-weight: 400

#header-wrapper.header-slider
	overflow: hidden
	height: 100%
	background: url("/img/bgstart.jpg") no-repeat center top
	background-attachment: fixed
	background-size: cover

#header-wrapper.header-slider .home-slide-content
	display: block
	overflow: hidden
	margin: 0 auto
	height: 2.4em
	line-height: 1.2em

#header-wrapper .scroll_btn
	display: block
	animation: 0.7s ease-in-out 2s normal backwards 1 intro_text_home
	transition: all 0.5s ease-in-out 0s
	margin: 42px auto
	border: 2px solid #fff
	border-radius: 50%
	width: 45px
	height: 45px
	background: url("/img/scroll.gif") no-repeat scroll 50% 50% transparent

#header-wrapper .scroll_btn:hover
	opacity: 0.85
	transform: scale(1.3, 1.3)
	background-color: #d7b55e

header .logo
	margin-bottom: 30px
	text-align: center
