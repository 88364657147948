.overlay
	position: absolute
	z-index: 999
	top: 0
	left: 0
	width: 100%
	height: 100%
	background: rgba(255,255,255,0.9)

	.overlay-close
		position: absolute
		top: 20px
		right: 20px
		overflow: hidden
		border: none
		width: 80px
		height: 80px
		background: url(../img/cross.png) no-repeat center center
		background-color: $colorMintGreen
		text-indent: 200%
		color: transparent
		outline: none
		z-index: 100

	nav
		position: relative
		height: 100%
		text-align: center


.overlay-genie
	visibility: hidden
	opacity: 0
	transition: opacity 0.3s 0.2s, visibility 0s 0.6s
	background: transparent

	svg
		position: absolute
		top: 0
		left: 0

	nav
		opacity: 0
		transition: opacity 0.3s 0.3s, -webkit-transform 0.3s 0.3s

	.overlay-path
		fill: rgba(255,255,255,0.9)
		-webkit-fill: rgba(255,255,255,0.9)
		-moz-fill:rgba(255,255,255,0.9)
		-o-fill:rgba(255,255,255,0.9)

	.overlay-close
		opacity: 0
		transition: opacity 0.3s

	&.open
		visibility: visible
		opacity: 1
		transition: opacity 0.3s

		nav,
		.overlay-close
			opacity: 1
			-webkit-transition-delay: 0.3s
			-moz-transition-delay: 0.3s
			-o-transition-delay: 0.3s
			transition-delay: 0.3s

	&.close
		nav,
		.overlay-close
			-webkit-transition-delay: 0s
			-moz-transition-delay: 0s
			-o-transition-delay: 0s
			transition-delay: 0s
