
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  cursor: ew-resize;
}
.owl-carousel .owl-wrapper:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.owl-carousel .owl-wrapper-outer {
  position: relative;
  width: 100%;
  height: auto !important;
  overflow: hidden;
}
.owl-carousel .owl-wrapper-outer .autoHeight {
  -webkit-transition: height 0.5s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: height 0.5s ease-in-out 0s;
  -o-transition: height 0.5s ease-in-out 0s;
  transition: height 0.5s ease-in-out 0s;
}
.owl-carousel .owl-item {
  position: relative;
  float: left;
  padding: 10px 0;
}
.owl-carousel .owl-prev:before {
  content: "\f177";
}
.owl-carousel .owl-next:before {
  content: "\f178";
}
.owl-carousel .owl-prev, .owl-carousel .owl-next {
  display: inline-block;
  position: relative;
  margin: 20px;
  color: rgba(255, 255, 255, 0.2);
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}
.owl-carousel .owl-prev:hover, .owl-carousel .owl-next:hover {
  color: #fff;
}
