#contact
	position: relative
	padding-bottom: 0

	.contact-form
		margin-top: 20px

	.popup-btn
		margin-top: 30px

.sidecontact
	position: relative
	padding-left: 60px

	.fa-map-marker
		position: absolute
		top: 0
		left: 0
		border-radius: 50%
		width: 40px
		height: 40px
		background-color: $colorWhite
		font-size: 30px
		line-height: 40px
		text-align: center
		color: $colorPurple

	h3
		margin-bottom: 10px
		padding-top: 10px

	h5
		margin: 20px 0 5px
		font-size: 16px

	// address
	// 	margin-bottom: 0
	// 	line-height: 1.6
	//
	// 	a
	// 		color: $colorWhite
	//
	// 	.fa
	// 		display: inline-block
	// 		width: 20px
		// width: 80px
		// height: 80px
// 	color: #d9b346
// 	font-size: 90px
// 	line-height: 90px
// 	text-align: center
// 	background: #fff
// 	-webkit-border-radius: 50%
// 	-moz-border-radius: 50%
// 	-ms-border-radius: 50%
// 	-o-border-radius: 50%
// 	border-radius: 50%
//
// .sidecontact i.fa-key
// 	font-size: 80px
// 	line-height: 80px
//
// .sidecontact h1
// 	margin-bottom: 0
// 	color: #fff
// 	font-size: 16px
// 	font-weight: 400
// 	line-height: normal
// 	text-align: center
