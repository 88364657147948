/**
 * navigation
 */
.navbar-purple
	// z-index: 10000
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 10px 0px
	border-color: transparent
	background-color: $colorPurple

	.container
		position: relative

	.navbar-toggle .icon-bar
		background-color: white

	.navbar-brand
		position: absolute
		top: 0
		left: 30px
		height: 100%
		padding: 8px 0

		img
			width: auto
			height: 100%

	.navbar-text
		color: $colorWhite

	.navbar-nav
		> li > a
			position: relative
			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out
			background-color: transparent
			font-size: 14px
			line-height: 50px
			font-weight: 600
			text-transform: uppercase
			color: $colorWhite

			&:after
				position: absolute
				top: 100%
				left: 0
				right: 0
				display: block
				visibility: hidden
				opacity: 0
				transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out
				height: 5px
				background-color: $colorMintGreen
				content: ' '

			&:hover
				transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out
				background-color: $colorPink
				color: $colorWhite

				&:after
					visibility: visible
					opacity: 1
					transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out

			&:focus
				outline: 0

		> .active > a
			&,
			&:hover,
			&:focus
				background-color: $colorPink
				color: $colorWhite

			&:after
				visibility: visible
				opacity: 1

		> .disabled > a
			&,
			&:hover,
			&:focus
				background-color: $colorPink
				color: $colorWhite



@media (min-width: $screen-sm-min)
	.navbar-fixed-top,
	.navbar-fixed-bottom
		.navbar-collapse
  		max-height: none

@media (max-width: $screen-md-min)
	.navbar-header
		position: relative
		float: none
		max-height: 50px

	.navbar-left,.navbar-right
		float: none !important

	.navbar-toggle
		display: block

	.navbar-fixed-top
		top: 0
		border-width: 0 0 1px

	.navbar-collapse.collapse
		display: none!important

	.navbar-nav
		float: none!important
		margin-top: 7.5px

	.navbar-nav>li
		float: none

	.navbar-nav>li>a
		padding-top: 10px
		padding-bottom: 10px
		line-height: 32px

	.collapse.in
		display:block !important
