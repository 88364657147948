.reveal-modal-bg
  position: fixed
  z-index: 9998
  top: 0
  left: 0
  display: none
  width: 100%
  height: 100%
  background: rgba($colorPink,0.9)

.reveal-modal
  position: absolute
  z-index: 9999
  top: 100px
  left: 20%
  right: 20%
  display: none
  transition: all 0.4s ease-in
  box-shadow: 0 0 5px rgba($colorBlack, 0.1)
  border: 1px solid #f2f2f2
  border-radius: 5px
  height: auto
  padding: 1.875em
  background: $colorWhite

.reveal-modal.small
  margin-left: -140px
  width: 200px

.reveal-modal.medium
  margin-left: -240px
  width: 400px

.reveal-modal.large
  margin-left: -340px
  width: 600px

.reveal-modal.xlarge
  margin-left: -440px
  width: 800px

.reveal-modal>*:last-child
  margin-bottom: 0

.reveal-modal h4
  margin-top: 20px

.reveal-modal .close-reveal-modal
  position: absolute
  top: 10px
  right: 20px
  cursor: pointer
  font-size: 40px
  line-height: 1
  font-weight: bold
  text-shadow: 0 -1px 1px rbga($colorBlack, 0.6)
  color: #aaa

.reveal-modal .close-reveal-modal:hover,
.reveal-modal .close-reveal-modal:focus
  cursor: pointer
  text-decoration: none
