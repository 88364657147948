/**
 * fonts
 */
@font-face
  font-family: 'Open Sans'
  src: font-url('OpenSans-Semibold-webfont.eot')
  src: font-url('OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'), font-url('OpenSans-Semibold-webfont.woff') format('woff'), font-url('OpenSans-Semibold-webfont.ttf') format('truetype'), font-url('OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: font-url('OpenSans-Light-webfont.eot')
  src: font-url('OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), font-url('OpenSans-Light-webfont.woff') format('woff'), font-url('OpenSans-Light-webfont.ttf') format('truetype'), font-url('OpenSans-Light-webfont.svg#open_sanslight') format('svg')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: font-url('OpenSans-Bold-webfont.eot')
  src: font-url('OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'), font-url('OpenSans-Bold-webfont.woff') format('woff'), font-url('OpenSans-Bold-webfont.ttf') format('truetype'), font-url('OpenSans-Bold-webfont.svg#open_sansbold') format('svg')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: font-url('OpenSans-Regular-webfont.eot')
  src: font-url('OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), font-url('OpenSans-Regular-webfont.woff') format('woff'), font-url('OpenSans-Regular-webfont.ttf') format('truetype'), font-url('OpenSans-Regular-webfont.svg#open_sansregular') format('svg')
  font-weight: 700
  font-style: normal
